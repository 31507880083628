import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Home from './components/Home';
import Baps from './components/Baps';
import Customer_Avatars from './components/customer_avatars';
import Lead_Magnets from './components/lead_magnets';
import Ideal_Book_Recommendation from './components/ideal_book_recommendation';
import Ideal_Book from './components/ideal_book';
import AudioBook from './components/audio_book';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import './dist/tailwind.css';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component
import Sales_letter from './components/sideBar';

const LoginButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect({
      scopes: ['user.read'],
      prompt: 'select_account', // Forces account selection
    });
  };

  return (
    <div>
      <button
        className='bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition duration-300'
        onClick={handleLogin}
      >
        Login
      </button>
    </div>
  );
};

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();

    // Use useEffect to save email to localStorage when accounts change
    useEffect(() => {
      if (accounts.length > 0) {
        const userEmail = accounts[0].username; // Assuming email is stored in username
        localStorage.setItem("user_email", userEmail);
      }
    }, [accounts]);

  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={
            isAuthenticated ? (
              <Home />
            ) : (
              <div className='flex flex-col justify-center items-center h-screen'>
                <p className='mb-4'>Please log in to access the application.</p>
                <LoginButton />
              </div>
            )
          }
        />
        <Route path='/baps' element={<ProtectedRoute element={<Baps />} />} />
        <Route
          path='/customer-avatars'
          element={<ProtectedRoute element={<Customer_Avatars />} />}
        />
        <Route
          path='/lead-magnets'
          element={<ProtectedRoute element={<Lead_Magnets />} />}
        />
        <Route
          path='/ideal-book-recommendation'
          element={<ProtectedRoute element={<Ideal_Book_Recommendation />} />}
        />
        <Route
          path='/ideal-book'
          element={<ProtectedRoute element={<Ideal_Book />} />}
        />
        <Route
          path='/audio-book'
          element={<ProtectedRoute element={<AudioBook />} />}
        />
        <Route
          path='/sales-letter'
          element={<ProtectedRoute element={<Sales_letter />} />}
        />
		<Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </Router>
  );
};

export default App;
