import React from 'react';
import RemoveMarkdown from 'remove-markdown';
import ReactMarkdown from "react-markdown";
import '../App.css';

const AvatarCard = ({ title, description = [] }) => {
  return (
    <div className="mobile-prest">
      <div className="relative flex flex-col px-4 pt-24 pb-12 font-sans text-gray-700 bg-blue-200 sm:px-6 lg:px-8 min-h-screen">
        <div className="flex-1 space-y-8 text-center">
          {description.slice(0, 5).map((desc, index) => {
            // Strip markdown to get plain text
            const structuredText = desc
              .replace(/```markdown/g, "")
              .replace(/```/g, "");

            // Format plain text into a structured format
            // const structuredText = plainText
            //   .replace(/Niche:\*\*/g, '<strong>Niche:</strong>')
            //   .replace(/Age:\*\*/g, '<strong>Age:</strong>')
            //   .replace(/Location:\*\*/g, '<strong>Location:</strong>')
            //   .replace(/Marital Status:\*\*/g, '<strong>Marital Status:</strong>')
            //   .replace(/Children:\*\*/g, '<strong>Children:</strong>')
            //   .replace(/Annual Income:\*\*/g, '<strong>Annual Income:</strong>')
            //   .replace(/Net Worth:\*\*/g, '<strong>Net Worth:</strong>')
            //   .replace(/Emotions:\*\*/g, '<strong>Emotions:</strong>')
            //   .replace(/Motivation:\*\*/g, '<strong>Motivation:</strong>')
            //   .replace(/Interests:\*\*/g, '<strong>Interests:</strong>')
            //   .replace(/Listen To:\*\*/g, '<strong>Listen To:</strong>')
            //   .replace(/Faced Failures In:\*\*/g, '<strong>Faced Failures In:</strong>')
            //   .replace(/Values:\*\*/g, '<strong>Values:</strong>')
            //   .replace(/Top Two Driving Forces:\*\*/g, '<strong>Top Two Driving Forces:</strong>')
            //   .replace(/Primary Question:\*\*/g, '<strong>Primary Question:</strong>')
            //   .replace(/\.\s/g, '.<br/>'); // Adds line breaks after each period

            return (
              <div
                key={index}
                className="sticky top-[calc(1rem_*_1)] w-full max-w-xl px-8 py-12 mx-auto space-y-4 bg-white border rounded-lg shadow-lg"
              >
                <h2 className="text-2xl font-bold leading-none text-gray-900 space-y-1">
                  <span className={`block text-sm ${getColor(index)}`}>
                    Customer {index + 1}
                  </span>
                </h2>
                {/* Render the structured text with markdown removed */}
                <pre><ReactMarkdown>{structuredText}</ReactMarkdown></pre>
                {/* <p dangerouslySetInnerHTML={{ __html: structuredText }} /> */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// Helper function for color
const getColor = (index) => {
  const colors = ['text-blue-700', 'text-red-700', 'text-yellow-700', 'text-purple-700', 'text-green-700'];
  return colors[index % colors.length];
};

export default AvatarCard;
